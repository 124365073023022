/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import Helmet from "react-helmet"
import {graphql, useStaticQuery} from "gatsby"

function SEO({description, lang, meta, title}) {
    const {site} = useStaticQuery(
        graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
    );

    const metaDescription = description || site.siteMetadata.description;
    const schemaOrgWebPage = {
        '@context': 'http://schema.org',
        '@type': 'WebPage',
        relatedLink: ["LINK", "LINK", "LINK", "LINK"],
        significantLink: "How Do I Suggest Addiction Treatment? | Confidant Health",
        alternativeHeadline: "How Do I Suggest Treatment?",
        description: "Breaching the subject of addiction with a loved one can be extremely sensitive - and a delicate approach is best. We help you make the most of this important conversation, so you can help them.",
        about: "addiction treatment",
        keywords: ["Addiction Treatment", "Suggesting Addiction Treatment"],
        url: "LINK",
        sameAs: ["SOCIAL MEDIA LINK", "SOCIAL MEDIA LINK", "SOCIAL MEDIA LINK"],
        audience: ["families of opioid addicts", "friends of opioid addicts",
            "opioid addicts"],
        typicalAgeRange: "18-65",
        author: "Confidant Health",
        contributor: "Confidant Health",
        copyrightHolder: "Confidant Health",
        creator: "Confidant Health",
        sourceOrganization: "Confidant Health",
        publisher: "Confidant Health",
        inLanguage: {lang},
        isAccessibleForFree: "true",
        isFamilyFriendly: "true",
        schemaVersion: "https://schema.org/version/3.9/",
        timeRequired: "5PM"
    };

    return (
        <Helmet
            htmlAttributes={{
                lang,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    name: `description`,
                    content: metaDescription,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:description`,
                    content: metaDescription,
                },
                {
                    property: `og:image`,
                    content: `IMAGE LINK`,
                },
                {
                    property: `og:url`,
                    content: `LINK`,
                },
                {
                    property: `site_name`,
                    content: `Confidant Health`,
                },
                {
                    property: `article:section`,
                    content: `ARTICLE SECTION`,
                },
                {
                    property: `og:article:author`,
                    content: `Confidant Health`,
                },
                {
                    property: `og:article:tag`,
                    content: `addiction treatment`,
                },
                {
                    property: `og:see_also`,
                    content: `LINK`,
                },

            ].concat(meta)}
        >
            <script type="application/ld+json">{JSON.stringify(
                schemaOrgWebPage)}</script>
        </Helmet>
    )
}

SEO.defaultProps = {
    lang: `en`,
    meta: [],
    description: ``,
};

SEO.propTypes = {
    description: PropTypes.string,
    lang: PropTypes.string,
    meta: PropTypes.arrayOf(PropTypes.object),
    title: PropTypes.string.isRequired,
};

export default SEO
